import React, { useState } from "react";
import { Col, Row } from "antd";
import { LogoWithTitle } from "../LogoWithTitle";
import mariticsLogo from "@assets/images/maritics_logo.svg";
import * as S from "./CollapsedHeader.style";
import * as CommonStyle from "@styles/CommonStyles.style";

interface ICollapsedHeaderProps {
  siderCollapsed: boolean;
  toggleSider: () => void;
}

const CollapsedHeader: React.FC<ICollapsedHeaderProps> = ({
  toggleSider,
  siderCollapsed,
}) => {
  return (
    <CommonStyle.WrapperRow style={{ zIndex: 999999 }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ width: "100%", zIndex: 99999 }}
      >
        <Col>
          <LogoWithTitle title="MARITICS" logo={mariticsLogo} />
        </Col>

        <S.IconWrapper>
          <S.MobileBurger onClick={toggleSider} isCross={!siderCollapsed} />
        </S.IconWrapper>
      </Row>
    </CommonStyle.WrapperRow>
  );
};

export default CollapsedHeader;
