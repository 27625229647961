import React from "react";
import { RequestStatus } from "@common/enums";
import { useAppSelector } from "@common/hooks/reduxHooks";
import { Features, Home, /*Pricing,*/ Contact } from "../components";

const MainPage: React.FC = () => {
  const licensesStatus = useAppSelector((state) => state.license.status);

  return (
    <React.Fragment>
      <Home id="home" />
      <Features id="features" />
      {/*{licensesStatus === RequestStatus.FULFILLED && <Pricing id="pricing" />}*/}
      <Contact id="contact" />
    </React.Fragment>
  );
};

export default MainPage;
